.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

header.masthead {
  position: relative;
  background-color: #343a40;
  background: url("bg2.jpg") no-repeat center center; /* Fallback for older browsers */
  background-image: image-set(
    url("bg2.avif") 1x, /* AVIF image */
    url("bg2.jpg") 1x  /* JPEG fallback */
  );
  background-size: cover;
  padding-top: 8rem;
  padding-bottom: 8rem;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.features-icons {
  padding-top: 7rem;
  padding-bottom: 7rem;
}

.features-icons .features-icons-item {
  max-width: 20rem;
}

.features-icons .features-icons-item .features-icons-icon {
  height: 4rem;
  padding-bottom: 5.5rem;
}

.features-icons .features-icons-item .features-icons-icon i {
  font-size: 4.5rem;
}

.features-icons .features-icons-item:hover .features-icons-icon i {
  font-size: 5rem;
}

.btn.question-selected {

}

.btn.question-not-selected {
  background-color: #FFFFFF;
}

.btn.question-not-selected:hover {
  background-color: rgb(10, 88, 202);
}

.question-box-narrow-page {
  display: none;
}

@media (max-width: 767px) {
  .question-box-narrow-page {
    display: inherit;
  }

  .question-box-normal-page {
    display: none;
  }
}

#share.dropdown-toggle::after{
  display: none; 
}

/* By default, display the elements */
.only-show-widescreen {
  display: block;
}

/* Using a media query to hide on devices with width less than or equal to 768px, which is a common breakpoint for mobile devices */
@media (max-width: 768px) {
  .only-show-widescreen {
      display: none;
  }
}
